<template>
  <div class="container">
    <div class="card border-0 shadow-none" v-if="template">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col">
            <h4 class="mb-0 text-success fw-bold">{{ template.title }}</h4>
          </div>
          <div class="col-auto ms-auto">
            <router-link
              to="/email-templates"
              class="btn btn-sm btn-outline-success"
            >
              <i class="far fa-arrow-left me-2"></i>Back
            </router-link>
          </div>
        </div>

        <!--  -->

        <div class="row mb-4">
          <div class="col">
            <label for="">Title</label>
            <input type="text" v-model="template.title" class="form-control" />
          </div>
          <div class="col">
            <label for="">Subject</label>
            <input
              type="text"
              v-model="template.subject"
              class="form-control"
            />
          </div>
        </div>

        <vue-editor class="mb-4" v-model="template.body"></vue-editor>

        <!--  -->

        <div class="row">
          <div class="col-auto ms-auto">
            <button class="btn btn btn-success" @click="updateTemplate">
              <i class="far fa-check me-2"></i>Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading.vue";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      template: null,
    };
  },
  methods: {
    fetchTemplate() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/email-templates/" +
            this.$route.params.id
        )
        .then(({ data }) => {
          this.template = data;
        });
    },
    updateTemplate() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/email-templates/" +
            this.$route.params.id,
          this.template
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    deleteTemplate() {
      if (
        confirm(
          "Are you sure you wish to delete this template, this action cannot be undone?"
        )
      ) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL +
              "/email-templates/" +
              this.$route.params.id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$router.push("/email-templates");
          });
      }
    },
  },
  created() {
    this.fetchTemplate();
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
  components: {
    Loading,
    VueEditor,
  },
};
</script>

<style></style>
